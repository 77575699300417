import React from "react";
import { Modal } from "react-bootstrap";
import "./modal.css";

const ModalBootstrap = props => {
  return (
    <div>
      <Modal
        className="internetModal"
        size="sm"
        show={props.isInternetModal}
        onHide={props.handleClose.bind(this)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="headerModal">
          <Modal.Title>Internet Warning!!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="noInternetText">
            An internet connection is required to ensure the latest information
            is up to date in Estate Clarity. Please connect your computer to the
            internet to proceed.
          </p>
          <button
            className="btn btn-primary btn-primary mrginToModl"
            onClick={props.handleClose}
          >
            Ok
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ModalBootstrap;
