import React, { Component } from 'react';
import "./reset.css";
import { connect } from 'react-redux';
import * as loginAction from '../../actions/index';

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }
    componentDidMount() {
        if (this.props.isSignedIn) {
            this.props.history.push("/dashboard")
        }
    }
    handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({ [key]: value });
    }
    onSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: this.state.email
        }
        this.props.resetPassword(data)
    }
    backLogin = (e) => {
        e.preventDefault()
        this.props.history.push("/login")

    }
    render() {

        return (
            <div className="container">
                <div className="row justified">
                    <div className="col-md-4 col-md-offset-4">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="text-center">
                                    <h3><i className="fa fa-lock fa-4x"></i></h3>
                                    <h2 className="text-center">Forgot Password?</h2>
                                    <p>You can reset your password here.</p>
                                    <div className="panel-body">

                                        <form id="reset-form" autoComplete="off" className="form" onSubmit={this.onSubmit} >

                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                                                    <input id="email" name="email" placeholder="email address" className="form-control" type="email" onChange={this.handleChange.bind(this)} required />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-lg btn-primary btn-block" type="submit"> Reset</button>
                                            </div>
                                            <button className="btn btn-secondary" onClick={this.backLogin}> Back To Login</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        isSignedIn: state.auth.isSignedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (data) => dispatch(loginAction.resetPassword(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reset)