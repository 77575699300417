import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from '../history';
import * as logoutAction from '../actions/index';

import LoginPage from '../components/loginPage/loginPage';
import Navbar from '../Layout/navbar';
import { connect } from 'react-redux';
import Footer from '../Layout/Footer/footer';

import "../App.css"
import LoginPageSubmit from './loginPage/loginPageSubmit';
import Dashboard from './dashboard/dashboard';
import ViewPage from './viewPage/viewPage';
import AdminDashboard from "./AdminDashboard/AdminDashboard"
import DashboardLeadCollab from './dashboard/DashboardLeadCollab';
import AppDownload from './AppDownload/AppDownLoad'
import Reset from './Reset/reset'
import ModalBootstrap from './modal/modal';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInternetModal: false
    }
  }
  componentDidMount() {
    let token= localStorage.getItem("userToken")
    this.onileOrNot()
    if(!token || !this.props.isSignedIn) {
      this.props.logout()
    }

  }
  onileOrNot = () => {
    if (!navigator.onLine) {
      this.setState({
        isInternetModal: true
      })
    }
  }
  componentDidUpdate() {
    let email = localStorage.getItem("email")

    if (!email) {
      history.push("/")
    }
  }
  handleClose = () => {
    this.setState({
      isInternetModal: false
    })
  }
  render() {
    const { isInternetModal } = this.state

    return (
      <div className="smallNavbar">
        <Router history={history}>
          <Navbar />
          <div className="ui container flex">
            <Switch>
              <Route path="/" exact render={(props) => <LoginPage history={props.history} onileOrNot={this.onileOrNot} isInternetModal={isInternetModal} />} />
              <Route path="/app-download" exact render={(props) => <AppDownload history={props.history}  />} />
              <Route path="/login" exact render={(props) => <LoginPageSubmit history={props.history} onileOrNot={this.onileOrNot} isInternetModal={isInternetModal} />} />} />
              <Route path="/reset-password" exact render={(props) => <Reset history={props.history} />} />
              <Route path="/dashboard" exact component={this.props.userType === "professional" ? Dashboard : (this.props.userType === "estate_lead"||this.props.userType === "collaborator") ? DashboardLeadCollab : AdminDashboard} />
              {/* <Route path="/dashboard" exact component={this.props.userType === "professional" ? Dashboard : this.props.userType === "estate_lead" ? DashboardLeadCollab : AdminDashboard} /> */}
              <Route path="/dashboard/:id/items" exact component={ViewPage} />
              <Redirect to="/login" />
            </Switch>
            <Footer />
          </div>
        </Router>
        <ModalBootstrap isInternetModal={isInternetModal} handleClose={this.handleClose} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userType: state.auth.loggedData.user_type,
    modalEstateLead: state.auth.loggedData.modalEstateLead
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction.signOut()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
