import React, { Component } from 'react'
import "./dashboard.css";
import { connect } from 'react-redux';
import * as dashboardAction from '../../actions/index';
import bessemer from '../../assets/images/bessemer3.png'; 
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import history from '../../history'
import { getEmail } from '../../commonFunctions/commonFunctions';
import { getInfoData } from "../../actions/index";
import { CSVLink } from "react-csv";
import moment from 'moment';
import { css } from '@emotion/core';
import ImageZoom from 'react-medium-image-zoom'

import { MoonLoader, ScaleLoader } from 'react-spinners';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Dashboard extends Component {
    csvLink = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            sizePerPageValue: 20,
            itemcount: 0,
            estate_: [],
            Arr: [],
            valuesToNextPage: [],
            csvData: [],
            name: "initial",
            loading:true,
            loadingCsv: false,
            estateId:null

        }
    }
    async componentDidMount() {
        this.props.removeViewPageData()
        let token = localStorage.getItem("userToken")
        await this.props.completeDetailOfUser(token, getEmail())
        if (!token) {
            history.push("/")
        }
        let myData = []
        let obData = {}
        for (var i = 0; i < this.props.estate_leads.length; i++) {
            for (var j = 0; j < this.props.estate_leads[i].estates.length; j++) {
                obData = {
                    index: i - 1,
                    fname: this.props.estate_leads[i].fname,
                    lname: this.props.estate_leads[i].lname,
                    estateData: this.props.estate_leads[i].estates[j],
                    mobile:this.props.estate_leads[i].mobile,
                    email:this.props.estate_leads[i].email
                }
                myData.push(obData)
            }
        }

        this.setState({ estate_: myData, loading:false })



    }
    onEyeClick = (row, rowIndex) => {
        let itemValueToPass= parseFloat(row.estateData.totalVal/100).toFixed(2)
        localStorage.setItem("nextPageValues", JSON.stringify({ "itemValue": itemValueToPass, "estateName": row.estateData.name, "items": row.estateData.items.length }))
        history.push(`/dashboard/${row.estateData.id}/items`)
    }
    modalForView = (cell, row, rowIndex) => {

        return (
            <i onClick={this.onEyeClick.bind(this, row, rowIndex)} className="fa fa-eye eyeClass"></i>
        )

    }
    downloadCsv = (id, name) => {
        this.setState({
            estateId:id,
            loadingCsv:true
        })

        getInfoData(id, (res) => {

            let filteredData = res['data'].data.filter((filterData, index) => {
                return filterData.type === "item"
            })
            let csvData = [['Timestamp', 'Title', 'Price','Category', 'Description','Destination','IsAppraised'], ...filteredData.map((response) => {
                return (
                    Object.values({
                        timestamp: response.created_at,
                        title: response.name,
                        price: `$${parseFloat(Math.round(response.value) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                        category:response.category,
                        description:response.description,
                        destination: response.destination,
                        isAppraised: response.is_appraised===true?"Yes":(response.is_appraised===false?"No":"")
                    })
                )
            })]
            this.setState({ csvData, name, loadingCsv:false }, () => {

                this.csvLink.current.link.click()
            })
        });
        
    }
    infoClick = (cell, row, rowIndex) => {
        return (
            <div>
                <p className="infoestate"> {row.estateData.name}</p>
                <div className="btnNdCsv"> 
                <button
                    onClick={this.downloadCsv.bind(this, row.estateData.id, row.fname)}
                    className="csvbutton">
                    <p className="smallSizebutton">Download CSV for all Items</p> <i className="fa fa-download completeicon" ></i>
                </button>
                <CSVLink
                    filename={this.state.name + '.csv'}
                    data={this.state.csvData}
                    className="hidden"
                    ref={this.csvLink}
                    target="_blank"
                />
                {row.estateData.id===this.state.estateId?
                <div className="text-center">
                    <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={25}
                        color={'#62a3f9'}
                        loading={this.state.loadingCsv}
                    />
                </div>
                : null}
                </div>
            </div>
        )
    }
    totalPages(sizePerPage, size) {

        let totalPages = size / sizePerPage;

        if (size % sizePerPage === 0) {
            return totalPages
        }
        else {
            return Math.trunc(totalPages) + 1
        }
    }
    customTotal = (estate_,from, to, size) => {

        return (
            this.state.loading === false && estate_ && estate_.length ?
                <div className="react-bootstrap-table-pagination-total">
                    Displaying  {from}-{to} of <b>{size}</b> Estates | Page {this.state.pageNumber} of {this.totalPages(this.state.sizePerPageValue, size)}
                </div>
                :
                null
        );
    }
    pageChange = (page, sizePerPage) => {
        this.setState({
            pageNumber: page,
            sizePerPageValue: sizePerPage
        })

    }
    onSizePerPageChange = (sizePerPage, page) => {
    }

    noOfItemFormatter = (cell, row, rowIndex) => {
        return (
            row.estateData.items.length
        )

    }
    clientEstateLeadname =(cell, row, rowIndex) => {
        return (
            <div>
                <p><b>{row.fname}</b></p>
                <p> <i>{row.email}</i></p>
                <p><i>{row.mobile}</i></p>
            </div>
        )

    } 
    lastActivityFormatter = (cell, row, rowIndex) => {
        if (row.estateData.items.length > 0) {
            var dt = new Date();
            var tz = -dt.getTimezoneOffset() / 60;
            // eslint-disable-next-line
            let modifiedAt = new Date(Math.max.apply(null, row.estateData.items.map(function (e) {
                if (e.modified_at) {

                    return (moment(new Date(e.modified_at)).add(tz, 'hours'));
                }
            })));
            // eslint-disable-next-line
            let lastActivity = new Date(Math.max.apply(null, row.estateData.items.map(function (e) {
                if (e.created_at) {

                    return moment(new Date(e.created_at)).add(tz, 'hours');
                }
            })));
            return (
                <div>
                    <b> Last Item added</b> <br />{moment(modifiedAt).format("MM/DD/YYYY-HH:mm:ss")} <br />
                    <b>Last Activity</b> <br />{moment(lastActivity).format("MM/DD/YYYY-HH:mm:ss")}
                </div>
            )
        }
        else {
            return ("No Items")
        }
    }
    totalEnteredValueFormatter = (cell, row, rowIndex) => {
        let updatedArr = [];
        let newArr=[...this.state.estate_];

        // eslint-disable-next-line
        newArr.forEach((val,index1) => {
            val['estateData']['totalVal'] = 0
            // eslint-disable-next-line
            val['estateData']['items'].forEach((item, index2) => {
                val['estateData']['totalVal'] = val['estateData']['totalVal'] + item.value
            })
            updatedArr.push(val)
        })
        this.setState({
            estate_:updatedArr
        })
        let totalFixedVal=parseFloat(row.estateData.totalVal/100).toFixed(2)
        let toalValtoShow= totalFixedVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${toalValtoShow}`
    }

    collaboratorsCountFormatter = (cell, row, rowIndex) => {
        return (row.estateData.collaborator_count)

    }

    render() {
        const options = {
            sizePerPage: 20,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal.bind(this,this.state.estate_),
            onPageChange: this.pageChange.bind(this),
            onSizePerPageChange: this.onSizePerPageChange,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.state.estate_.length
            }] // A numeric
        };
        const defaultSorted = [{
            dataField: 'name',
            order: 'asc',
        }];
        const columns = [{
            dataField: 'any2',
            text: 'Client/Estate Lead Name',
            headerStyle: (colum, colIndex) => {
                return { width: '18%', textAlign: 'left' };
            },
            formatter: this.clientEstateLeadname.bind(this),

    
        },
        {
            dataField: 'none',
            text: 'Estate Info',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '21.5%', textAlign: 'left' };
            },
            formatter: this.infoClick.bind(this),
            formatExtraData: this.state.csvData && this.state.loadingCsv
        },
        {
            dataField: 'none2',
            text: 'Number Of Items',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%', textAlign: 'left' };
            },
            formatter: this.noOfItemFormatter.bind(this),

        },
        {
            dataField: 'estateData.id',
            text: 'Total Entered value',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%', textAlign: 'left' };
            },
            formatter: this.totalEnteredValueFormatter.bind(this),


        },
        {
            dataField: 'last_activity',
            text: 'Last Activity',
            formatter: this.lastActivityFormatter.bind(this),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%', textAlign: 'left' };
            },
        },
        {
            dataField: 'collaborators',
            text: 'Collaborators',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%', textAlign: 'left' };
            },
            formatter: this.collaboratorsCountFormatter.bind(this),

        },
        {
            dataField: 'id',
            text: 'View',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%', textAlign: 'left' };
            },
            formatter: this.modalForView.bind(this),
        },
        ];

        return (
            <div className="dashboard container">
                <div className="row">

                    <div className="col-sm-3 outerLogoDiv">
                        {this.props.logoUrl ?
                            <ImageZoom
                                image={{
                                    src: this.props.logoUrl,
                                    alt: 'loginImg',
                                    className: 'logoForPro',
                                }}
                                zoomImage={{
                                    src: this.props.logoUrl,
                                    alt: 'loginImg'
                                }}
                            />
                            :
                            <img className="logoForPro" src={bessemer} alt="loginDefaultImg" />
                        }
                    </div>
                    <div className="col-sm-9 inform">
                        <p className="welcomeTag"> <b>Welcome to Estate Clarity Professional Admin Portal, {this.props.loggedData.first_name.toUpperCase()} {this.props.loggedData.last_name.toUpperCase()}</b> </p> <br />
                        <p className="clientsEstates"><b> Clients with Estates:
                            {this.state.estate_.length}
                        </b></p> <br />
                        <p className="portalView"> <i>Use this portal to view the activity in your Client's estates.You can easily view the overall health of the estate, prioritize where you can offer the most to your clients, and download data associated with each estate. <br />
                            Click to any estate below to view images and item level details. </i></p> <br />
                        <p className="portalView" > <i> To add more users, email <a href={"mailto:support@estateclarity.com"}>support@estateclarity.com</a> with a list of your users. Remember, when adding new users, it is always best if you reach out to them first, introducing them to Estate Clarity. </i></p>
                    </div>
                    <div className="container bootstraptable">
                        <BootstrapTable
                            keyField="estateData.id"
                            data={this.props.userType === "professional" && this.state.estate_ ? this.state.estate_ : []}
                            columns={columns}
                            striped
                            hover
                            condensed
                            pagination={paginationFactory(options)}
                            defaultSorted={defaultSorted}

                        />
                    </div>
                    {this.state.estate_.length === 0 && this.state.loading===false ? <div className="noEstateContainer">
                        <p className="noEstate"><b>So far none of your clients has created an estate.</b></p>

                        <p className="lightNoEstate" > <i>(if you haven't provided Estate Clarity with your client list yet, that could be why!)</i></p>
                        {/* <p className="noEstate" > <b>if you'd like, Estate Clarity can reach out to these clients on your behalf -- just drop us an email at <a href="http://estateclarity.com/">support@estateclarity.com</a></b></p> */}
                        <p className="noEstate" > <b>if you'd like, Estate Clarity can reach out to these clients on your behalf -- just drop us an email at <a href={"mailto:support@estateclarity.com"}>support@estateclarity.com</a></b></p>
                    </div> : <MoonLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'blue'}
                            loading={this.state.loading}
                        />}
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        userType: state.auth.loggedData.user_type,
        noOfEstates: state.userData.noOfEstates,
        loggedData: state.auth.loggedData,
        estate_leads: state.userData.estateLeads,
        logoUrl: state.auth.logoUrl

    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        completeDetailOfUser: (token, email) => dispatch(dashboardAction.completeDetailOfUser(token, email)),
        removeViewPageData: () => dispatch(dashboardAction.removeViewPageData()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard) 
