import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const setAuth = (token) => {
    localStorage.setItem("userToken", token);
}
export const getAuth = () => {
    return localStorage.getItem("userToken");
}
export const setEmail = (email) => {
    localStorage.setItem("email", email);
}
export const getEmail = () => {
    return localStorage.getItem("email");
}
export const headers = () => {
    return {
        header: {
            'Content-Type': 'application/json'
        }
    }

};

export const headersWithBearer = (token) => {
    return{
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    
};
export const successResponse = (result, message) => {

    let res = {
        status: true,
        message: message,
        response: result ? result : null,
        type: 'Success!'
    };
    return res;
}

export const errorEmail= (err) => {
    if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.all[0]) {
       return toast.error(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.all[0])
    }

    else {
      return toast.error("SomeThing Went Wrong")
    }
}
export const EmailNotInDb= (err) => {
    if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.auth[0]) {
       return err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.auth[0]
    }

    else {
      return toast.error("SomeThing Went Wrong")
    }
}