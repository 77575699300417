const initialState = {
    isSignedIn: false,
    token: null,
    loggedData: {},
    logoUrl: "",
    modalState: false,
    errorText: "",
    noOfEstates: null,
    modalEstateLead: false,
    modalAdmin: false,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case "SIGN_IN_EMAIL":
            return { ...state, modalState: false }
        case "SIGN_IN":
            return { ...state, isSignedIn: true, loggedData: action.payload, token: action.payload.token }
        case "SIGN_OUT":
            return { ...state, isSignedIn: false, token: null, loggedData: [] }
        case "ERROR_MODAL":
            return { ...state, modalState: true }
        case "HANDLE_CLOSE":
            return { ...state, modalState: false }
        case "HANDLE_CLOSE_ESTATE":
            return { ...state, modalEstateLead: false }
        case "HANDLE_CLOSE_ADMIN_MODAL":
            return { ...state, modalAdmin: false }
        case "HANDLE_OPEN_ESTATE":
            return { ...state, modalEstateLead: true }
        case "HANDLE_OPEN_ADMIN_MODAL":
            return { ...state, modalAdmin: true }
        case "USER_LOGO":
            return { ...state, logoUrl: action.payload.business_logo }

        default:
            return state

    }
}