
import * as actionTypes from "../actions/actionTypes"
import history from '../history';
import API from '../apis/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setEmail, setAuth, getEmail, successResponse, headers, headersWithBearer, errorEmail, EmailNotInDb } from '../commonFunctions/commonFunctions';

export const modalEstateLeadfun = (data) => (dispatch, getState) => {


    dispatch({
        type: actionTypes.HANDLE_OPEN_ESTATE,
    })

}
export const modalAdminFun = () => (dispatch, getState) => {


    dispatch({
        type: actionTypes.HANDLE_OPEN_ADMIN_MODAL,
    })

}

export const signIn = (data) => (dispatch, getState) => {



    API.post('api/sessions', {
        "session": {
            "email": data.email,
            "password": data.password
        }
    }, headers).then((response) => {
        if (response.status === 200) {
            toast("Login SuccessFull")
            localStorage.setItem("token", response.data && response.data.data && response.data.data.token)


            dispatch({
                type: actionTypes.SIGN_IN,
                payload: response.data.data
            })
        }
    }, err => {
        toast.error("SomeThing Went Wrong")

    })
}
export const particularAccountUsersFun = (params, cb) => async (dispatch, getState) => {


    API.get(`/api/professional/user/${params.id}`, headersWithBearer(params.token)).then((response) => {
        if (response.data) {
            dispatch({
                type: actionTypes.PARTICULAR_ACCOUNT_DATA,
                payload: response.data
            })
        }
        cb(successResponse(response, null));
    })

}
export const completeDetailOfUser = (token, email) => async (dispatch, getState) => {


    await API.get(`https://dev.estateclarity.com/api/professional/user/details/99`, headersWithBearer(token)).then((response) => {


        if (response.data) {
            dispatch({
                type: actionTypes.ESTATE_LEADS,
                payload: response.data
            })
        }
    })

}

export const getLogo = (email) => async (dispatch) => {

    API.get(`/api/fetch/business/logo/?email=${email}`).then((response) => {

        if (response.data) {
            dispatch({
                type: actionTypes.USER_LOGO,
                payload: response.data
            })
        }
    })

}
export const getEstatesFromDb = (token) => async (dispatch) => {

    API.get(`/api/estates`, headersWithBearer(token)).then((response) => {

        if (response.data) {
            dispatch({
                type: actionTypes.ESTATE_DATA_FROM_DB,
                payload: response.data
            })
        }
    })

}
export const getEstateItemsDb = (token) => async (dispatch) => {

    API.get(`/api/professional/user/details/99`, headersWithBearer(token)).then((response) => {
        

        if (response.data) {
            dispatch({
                type: actionTypes.ESTATE_ITEMS_DATA_FROM_DB,
                payload: response.data
            })
        }
    })

}


export const loginEmail = (data, cb) => (dispatch, getState) => {

    setEmail(data.email);
    API.post('api/sessions', {
        "session": {
            "email": data.email
        }
    }, headers).then((response) => {
        if (response.status === 200) {
            dispatch({
                type: actionTypes.SIGN_IN_EMAIL,

                payload: response.data
            })
            history.push("/login")
        }

    }).catch(error => {
        if (EmailNotInDb(error)) {
            dispatch({
                type: actionTypes.ERROR_MODAL,
            })
        }

        else { toast.error("Something Went Wrong") }

    });
}

export const loginPassword = (data, cb) => (dispatch, getState) => {
    let email = getEmail();

    API.post('api/sessions', {
        "session": {
            "email": email,
            "password": data.password
        }
    }, headers).then((response) => {
        if (response.status === 200) {
            // toast("Login SuccessFull")
            setAuth(response.data && response.data.data && response.data.data.token)
            dispatch({
                type: actionTypes.SIGN_IN,

                payload: response.data.data
            })
            history.push("/dashboard")
        }
        if ((response.data && response.data.data && response.data.data.user_type) === "professional" ||
            (response.data && response.data.data && response.data.data.user_type) === "estate_lead"
        ) {
            toast("Login SuccessFull")
        }

    }, err => {
        errorEmail(err)

    })
}


export const resetPassword = (data) => (dispatch, getState) => {


    API.post('api/forgot_password', data,
        headers).then((response) => {
            if (response.status === 200) {
                toast.success("Check Your Email")

                history.push("/login")
            }

        }, err => {
            let error = JSON.stringify(err.response.data.error)
            if (error) {
                toast.error(err.response.data.message)
            }
        })
}

export const professionalAccountDetailsFun = (token) => async (dispatch, getState) => {

    const response = await API.get('api/professional/account', headersWithBearer(token))

    dispatch({
        type: actionTypes.PROFESSIONAL_ACCOUNT_DETAILS,
        payload: response.data
    })
}


export const imageUpload = (params, cb) => async (dispatch, getState) => {
    let token = params.token;
    delete params.token;

    API.post("/api/s3/sign/upload", params, token)
        .then(response => {
            if (response.data) {

                dispatch({
                    type: actionTypes.LOGO_IMAGE_URL,
                    payload: response.data
                })
                cb(successResponse(response, null));
            }
        })
        .catch(error => { });

};
export const getInfoData = async (id, cb) => {

    var getTokenData = localStorage.getItem('userToken');

    var headers = {
        'Authorization': 'Bearer ' + getTokenData
    }
    await API.get(`api/estates/${id}/activities`, { headers })
        .then((response) => {

            cb(response)
        })
        .catch(function (error) {
        });
}
export const getCollaborators = async (id, cb) => {

    var getTokenData = localStorage.getItem('userToken');

    var headers = {
        'Authorization': 'Bearer ' + getTokenData
    }
    await API.get(`api/estates/${id}/users`, { headers })
        .then((response) => {

            cb(response)
        })
        .catch(function (error) {
        });
}
export const getActivities = (id, token,cb) => (dispatch, getState) => {


    var headers = {
        'Authorization': 'Bearer ' + token
    }
    API.get(`api/estates/${id}/activities`,
        { headers }).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: actionTypes.VIEW_PAGE_DATA,
                    payload: response.data
                })
                cb(successResponse(response, null));
            }
        })
}
export const getRecipient = (id, token,cb) => (dispatch, getState) => {


    var headers = {
        'Authorization': 'Bearer ' + token
    }
    API.get(`api/estates/${id}/items/stats`,
        { headers }).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: actionTypes.RECIPIENT_DATA,
                    payload: response.data
                })
                cb(successResponse(response, null));
            }
        })
}


export const getDiscriptionAndDestinationFromDbEstate = async (id, cb) => {
    var getTokenData = localStorage.getItem('userToken');

    var headers = {
        'Authorization': 'Bearer ' + getTokenData
    }
    await API.get(`api/estates/${id}/items/filter/destination/sell`, { headers }).then((response) => {
        cb(response)

    })
        .catch(function (error) {
        });
}
export const getDiscriptionAndDestinationFromDbProfessional = (id, cb) => async (dispatch, getState) => {
    var getTokenData = localStorage.getItem('userToken');

    var headers = {
        'Authorization': 'Bearer ' + getTokenData
    }
    await API.get(`api/estates/${id}/items/filter/destination/sell`, { headers }).then((response) => {
        if (response.data) {
            dispatch({
                type: actionTypes.VIEW_PAGE_DATA_STATS,
                payload: response.data
            })
        }
    })
        .catch(function (error) {
        });
}
export const signOut = () => (dispatch, getState) => {
    localStorage.removeItem("userToken")
    localStorage.removeItem("nextPageValues")
    dispatch({
        type: actionTypes.SIGN_OUT,
    })
}
export const handleClose = () => {
    return {
        type: actionTypes.HANDLE_CLOSE,
    }
}
export const handleCloseEstate = () => {
    return {
        type: actionTypes.HANDLE_CLOSE_ESTATE,
    }
}
export const handleCloseAdminModal = () => {
    return {
        type: actionTypes.HANDLE_CLOSE_ADMIN_MODAL,
    }
}
export const removeViewPageData = () => {
    return {
        type: actionTypes.REMOVE_VIEW_PAGE_DATA,
    }
}
