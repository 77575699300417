import React, { Component } from 'react'
import "./loginPage.css";
import { connect } from 'react-redux';
import * as loginAction from '../../actions/index';
import loginLogo from '../../assets/images/EstateClarityLogo_Blue_HorizAsset 2@0.5x.png';
import bessemer from '../../assets/images/bessemer3.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: ""
        }
    }
    componentDidMount() {
        let token = localStorage.getItem("userToken")
        let email = localStorage.getItem("email")

        if (token) {
            this.props.history.push("/dashboard")
        }
        if (!email) {
            this.props.history.push("/")
        }
    }
    loginSubmit = (event) => {
        event.preventDefault()
        let data = {
            password: this.state.password,
        }
        if (!navigator.onLine) {
            this.props.onileOrNot()
        }
        if (navigator.onLine) {
            this.props.loginPassword(data, (res) => {
            }
            )
        }
    }
    handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({ [key]: value });
    }
    render() {
        return (
            <div className="container fit-content">


                <div className="outerLogoImg"><img className="loginlogosubmit" src={loginLogo} alt="loginImg" /></div>
                <br />
                <p className="paraTag">For</p>
                {this.props.logoUrl ?
                    <div style={{ textAlign: "center" }}><img className="loginlogoUser" src={this.props.logoUrl} alt="loginImg" /> </div> :
                    <div style={{ textAlign: "center" }} className=""> <img className="loginlogoUser" src={bessemer} alt="loginDefaultImg" /> </div>
                }


                <form className="form-signin submitone" onSubmit={this.loginSubmit.bind(this)}>
                   <div className="password-field">
                    <input name="password" type="password" id="inputPassword" className="form-control marginInput" onChange={this.handleChange} placeholder="Password" required autoFocus />
                    <button className="btn btn-lg  btn-block btn-signin" type="submit">Login</button>
                    <div className="forgotDiv"> <Link className="forgot-loginSubmit" to="/reset-password"> Forgot Password?</Link></div>
                    </div>
                </form>

            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {

        loggedData: state.auth.loggedData,
        logoUrl: state.auth.logoUrl
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loginPassword: (data, cb) => dispatch(loginAction.loginPassword(data, cb)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
