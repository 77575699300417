import React, { Component } from 'react'
import "./dashboard.css";
import { connect } from 'react-redux';
import bessemer from '../../assets/images/bessemer3.png';
import history from '../../history';
import { Modal } from 'react-bootstrap';
import * as dashBoardLeadAction from '../../actions/index';
import { CSVLink } from "react-csv";
import { getInfoData } from "../../actions/index";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ImageZoom from 'react-medium-image-zoom';
import moment from 'moment';
import { css } from '@emotion/core';
import { ScaleLoader } from 'react-spinners';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class Dashboard extends Component {
    csvLink = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            csvData: [],
            name: "",
            pageNumber: 1,
            sizePerPageValue: 20,
            estateItemsStateData: this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.estates,
            loading: false,
            estateId: null
        }
    }
    componentDidMount() {
        this.props.removeViewPageData()
        let token = localStorage.getItem("userToken")
        if (!token || !this.props.isSignedIn) {
            this.props.logout()
            history.push("/")
        }
        if (this.props.noOfEstates === 0) {
            this.props.modalEstateLeadfun()
        }
        else {
            this.props.handleCloseEstate()
        }

        this.props.getEstatesFromDb(token)
        this.props.getEstateItemsDb(token)
    }
    static getDerivedStateFromProps(props, state) {
        if (state.estateItemsStateData !== (props.estateItemsData && props.estateItemsData.estate_lead && props.estateItemsData.estate_lead.estates)) {
            return {
                estateItemsStateData: props.estateItemsData && props.estateItemsData.estate_lead && props.estateItemsData.estate_lead.estates,

            };
        }
        else {
            return {
                estateItemsStateData: state.estateItemsStateData,
            }
        }
    }

    onEyeClick = (row) => {
        history.push(`/dashboard/${row.id}/items`)


    }
    handleClose = () => {
        this.props.handleCloseEstate()
    };
    infoClick = (cell, row, rowIndex) => {
        return (
            <div>
                <p className="infoestate"> {row.name}</p>
                <button
                    className="csvbutton">
                    Download CSV for all Items <i className="fa fa-download completeicon" ></i>
                </button>
            </div>
        )
    }
    CSVButtonFormatter = (cell, row, rowIndex) => {

        return (
            <div>
                <p><b> {row.name}</b></p>
                <button onClick={this.downloadCsv.bind(this, row.id, row.name)} className="csvbuttonlead">Download CSV of All items</button>
                <CSVLink
                    filename={this.state.name + '.csv'}
                    data={this.state.csvData}
                    className="hidden"
                    ref={this.csvLink}
                    target="_blank"
                />
                {this.state.estateId === row.id ?
                    <div className="text-center">
                        <ScaleLoader
                            css={override}
                            sizeUnit={"px"}
                            size={25}
                            color={'#62a3f9'}
                            loading={this.state.loading}
                        />
                    </div>

                    :
                    null}

            </div>
        )
    }
    downloadCsv = (id, name) => {

        getInfoData(id, (res) => {

            let filteredData = res['data'].data.filter((filterData, index) => {
                return filterData.type === "item"
            })
            let csvData = [['Timestamp', 'Title', 'Price', 'Category', 'Description', 'Destination', 'IsAppraised'], ...filteredData.map((response) => {
                return (
                    Object.values({
                        timestamp: response.created_at,
                        title: response.name,
                        price: `$${parseFloat(Math.round(response.value) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                        category: response.category,
                        description: response.description,
                        destination: response.destination,
                        isAppraised: response.is_appraised === true ? "Yes" : (response.is_appraised === false ? "No" : "")

                    })
                )
            })]
            this.setState({ csvData, name, loading: false }, () => {

                this.csvLink.current.link.click()
            })
        });
        this.setState({
            loading: true,
            estateId: id
        })
    }
    allImageDataPage = (data) => {
        history.push(`/dashboard/${data.id}/items`)
    }
    SerialNo = (cell, row, rowIndex) => {
        return (row.serialNo)
    }
    customTotal = (from, to, size) => {
        return (
            this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.estates && this.props.estateItemsData.estate_lead.estates.length ?
                <div className="react-bootstrap-table-pagination-total">
                    Displaying {from}-{to} of <b>{size}</b> Estates | Page {this.state.pageNumber} of {this.totalPages(this.state.sizePerPageValue, size)}
                </div> :
                null
        );
    }
    pageChange = (page, sizePerPage) => {
        this.setState({
            pageNumber: page,
            sizePerPageValue: sizePerPage
        })

    }
    totalPages(sizePerPage, size) {

        let totalPages = size / sizePerPage;

        if (size % sizePerPage === 0) {
            return totalPages
        }
        else {
            return Math.trunc(totalPages) + 1
        }
    }
    noOfItemFormatter = (cell, row, rowIndex) => {
        return (
            row.items.length
        )

    }
    totalEnteredValueFormatter = (cell, row, rowIndex) => {
        let updatedArr = [];
        let newArr = [...this.state.estateItemsStateData];
        newArr.forEach((val, index1) => {
            val['totalVal'] = 0
            val['items'].forEach((item, index2) => {
                val['totalVal'] = val['totalVal'] + item.value
            })
            updatedArr.push(val)
        })
        this.setState({
            estateItemsStateData: updatedArr
        })

        return `$${parseFloat(row.totalVal / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }
    totalEnteredValueFormatterCollaborator = (cell, row, rowIndex) => {
        let sum = row.items.reduce((a, b) => +a + +parseInt(b.value?b.value:0, 10), 0)
        return `$${parseFloat(sum / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`

    }
    lastActivityFormatter = (cell, row, rowIndex) => {
        if (row.items.length > 0) {
            var dt = new Date();
            var tz = -dt.getTimezoneOffset() / 60;
            // eslint-disable-next-line
            let modifiedAt = new Date(Math.max.apply(null, row.items.map(function (e) {
                if (e.modified_at) {

                    return moment(new Date(e.modified_at)).add(tz, 'hours');
                }
            })));
            // eslint-disable-next-line
            let lastActivity = new Date(Math.max.apply(null, row.items.map(function (e) {
                if (e.created_at) {

                    return moment(new Date(e.created_at)).add(tz, 'hours');
                }
            })));
            return (
                <div>
                    <b> Last Item added</b> <br />{moment(modifiedAt).format("MM/DD/YYYY-HH:mm:ss")} <br />
                    <b>Last Activity</b> <br />{moment(lastActivity).format("MM/DD/YYYY-HH:mm:ss")}
                </div>
            )
        }
        else {
            return ("No Items")
        }
    }
    collaboratorsCountFormatter = (cell, row, rowIndex) => {
        return (row.collaborator_count)

    }
    modalForView = (cell, row, rowIndex) => {
        return (
            <i onClick={this.onEyeClick.bind(this, row, rowIndex)} className="fa fa-eye eyeClass"></i>
        )

    }
    clientEstateLeadname = (cell, row, rowIndex) => {
        return (
            <div>
                <p><b>{this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.fname} {this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.lname}</b></p>
                <p> <i>{this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.email}</i></p>
                <p><i>{this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.mobile}</i></p>
            </div>
        )

    }
    clientCollabName = (cell, row, rowIndex) => {
        return (
            <div>
                <p><b>{this.props.loggedData && this.props.loggedData.username}</b></p>
                <p> <i>{this.props.collabEmail}</i></p>
                <p><i>{this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.mobile}</i></p>
            </div>
        )

    }
    render() {
        const options = {
            sizePerPage: 20,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            onPageChange: this.pageChange.bind(this),
            onSizePerPageChange: this.onSizePerPageChange,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.estates && this.props.estateItemsData.estate_lead.estates.length
            }] // A numeric
        };
        const columns = [
            {
                dataField: 'any',
                text: 'Client/Estate Lead Name',
                formatter: this.clientEstateLeadname.bind(this),
                headerColumnStyle: {
                    backgroundColor: 'blue'
                }
            },
            {
                dataField: 'none1',
                text: 'Estate Info',
                formatter: this.CSVButtonFormatter.bind(this),
                formatExtraData: this.state.csvData && this.state.loading
            },
            {
                dataField: 'none2',
                text: 'Number Of Items',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.noOfItemFormatter.bind(this),

            },
            {
                dataField: 'estateData.id',
                text: 'Total Entered value',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.totalEnteredValueFormatter.bind(this),


            },
            {
                dataField: 'last_activity',
                text: 'Last Activity',
                formatter: this.lastActivityFormatter.bind(this),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
            },
            {
                dataField: 'collaborators',
                text: 'Collaborators',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.collaboratorsCountFormatter.bind(this),

            },
            {
                dataField: 'id',
                text: 'View',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.modalForView.bind(this),
            },
        ]
        const collaboratorColumns = [
            {
                dataField: 'any4',
                text: 'Client/Estate Lead Name',
                formatter: this.clientCollabName.bind(this),
                headerColumnStyle: {
                    backgroundColor: 'blue'
                }
            },
            {
                dataField: 'none1',
                text: 'Estate Info',
                formatter: this.CSVButtonFormatter.bind(this),
                formatExtraData: this.state.csvData && this.state.loading
            },
            {
                dataField: 'none2',
                text: 'Number Of Items',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.noOfItemFormatter.bind(this),

            },
            {
                dataField: 'none3',
                text: 'Total Entered value',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '15%', textAlign: 'left' };
                },
                formatter: this.totalEnteredValueFormatterCollaborator.bind(this),


            },
            {
                dataField: 'last_activity',
                text: 'Last Activity',
                formatter: this.lastActivityFormatter.bind(this),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left' };
                },
            },
            // {
            //     dataField: 'collaborators',
            //     text: 'Collaborators',
            //     sort: true,
            //     headerStyle: (colum, colIndex) => {
            //         return { width: '15%', textAlign: 'left' };
            //     },
            //     formatter: this.collaboratorsCountFormatter.bind(this),

            // },
            {
                dataField: 'any',
                text: 'View',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left' };
                },
                formatter: this.modalForView.bind(this),
            },
        ]
        const rowStyle = { cursor: 'pointer' };
        return (
            <div className="dashboard container">
                <div className="row">
                    <div className="col-sm-3 outerLogoDiv">
                        {this.props.logoUrl ?
                            // <img className="logoForLead" src={this.props.logoUrl} alt="loginImg" /> 
                            <ImageZoom
                                image={{
                                    src: this.props.logoUrl,
                                    alt: 'DashBoardImage',
                                    className: 'logoForLead',
                                }}
                                zoomImage={{
                                    src: this.props.logoUrl,
                                    alt: 'loginImg'
                                }}
                            />
                            :
                            <ImageZoom
                                image={{
                                    src: bessemer,
                                    alt: 'DashBoardImage',
                                    className: 'logoForLead',
                                }}
                                zoomImage={{
                                    src: bessemer,
                                    alt: 'ZoomDashboard'
                                }}
                            />
                        }
                    </div>
                    <div className="col-sm-9 inform">
                        <p className="welcomeTag">
                            <b>
                                Welcome,  {this.props.loggedData && this.props.loggedData.username ?
                                    this.props.loggedData && this.props.loggedData.username && this.props.loggedData.username.toUpperCase() :
                                    this.props.loggedData && this.props.loggedData.first_name && this.props.loggedData.first_name.toUpperCase()
                                }{(this.props.loggedData && this.props.loggedData.username) || (this.props.loggedData && this.props.loggedData.first_name) ? "." : null}
                            </b>
                        </p>
                        <br />
                        <p className="clientsEstates"><b> You are a member of {this.props.noOfEstates} {this.props.noOfEstates > 1 ? "estates" : "estate"}.</b></p> <br />
                        <p className="portalView"> <i>Use this portal to view the activity in each estate you are participating in. You can easily view lists of items, see their destinations and values, and download all of the images and data associated with each estate. </i></p> <br />
                        <p className="portalView" > Soon you'll be able to add and edit items from this portal, but now you need to use the app to add and edit items.</p>
                    </div>
                    {this.props.userType === "estate_lead" ?
                        <div className="container bootstraptable">
                            <BootstrapTable
                                keyField='id'
                                data={(this.props.userType === "estate_lead" || this.props.userType === "collaborator") && this.props.noOfEstates > 0 && (this.props.estateItemsData && this.props.estateItemsData.estate_lead && this.props.estateItemsData.estate_lead.estates) ? this.props.estateItemsData.estate_lead.estates : []}

                                columns={columns}
                                striped
                                hover
                                condensed
                                tabIndexCell={true}
                                pagination={paginationFactory(options)}
                                rowStyle={rowStyle}
                            />
                        </div> :
                        (this.props.userType === "collaborator" ?
                            <div className="container bootstraptable">
                                <BootstrapTable
                                    keyField='id'
                                    data={(this.props.userType === "collaborator") && this.props.noOfEstates > 0 && (this.props.estateDataDb) ? this.props.estateDataDb : []}

                                    columns={collaboratorColumns}
                                    striped
                                    hover
                                    condensed
                                    tabIndexCell={true}
                                    pagination={paginationFactory(options)}
                                    rowStyle={rowStyle}
                                />
                            </div> : null)
                    }
                </div>
                {/* Modal For Estate Lead */}

                <Modal size="sm"
                    show={this.props.modalEstateLead}

                    onHide={this.handleClose.bind(this)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Body>

                        <p className="modalEstate"> <b>You must use the Estate Clarity app to create your estate</b></p>

                        <p className="modalEstate"> <b>If you need to download Estate Clarity,use this link:</b></p>
                        <p className="modalEstate" ><a href="http://download.estateclarity.com">download.estateclarity.com</a></p>
                        <p className="modalEstate"> <b>After you have gotten started in the app, you can come back to this page to view your work</b></p>
                        <hr />
                        <button className="btn btn-primary mrginToModl" onClick={this.handleClose}> Ok</button>
                    </Modal.Body>
                </Modal>


            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        userType: state.auth.loggedData.user_type,
        noOfEstates: state.userData.noOfEstates,
        modalEstateLead: state.auth.modalEstateLead,
        loggedData: state.auth.loggedData,
        logoUrl: state.auth.logoUrl,
        isSignedIn: state.auth.isSignedIn,
        estateItemsData: state.userData.estateItemsData,
        estateDataDb: state.userData.estateDataDb,
        collabEmail: state.auth.loggedData.email
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleCloseEstate: () => dispatch(dashBoardLeadAction.handleCloseEstate()),
        modalEstateLeadfun: () => dispatch(dashBoardLeadAction.modalEstateLeadfun()),
        getEstatesFromDb: (token) => dispatch(dashBoardLeadAction.getEstatesFromDb(token)),
        getEstateItemsDb: (token) => dispatch(dashBoardLeadAction.getEstateItemsDb(token)),
        getInfoData: (id, name) => dispatch(dashBoardLeadAction.getInfoData(id, name)),
        logout: () => dispatch(dashBoardLeadAction.signOut()),
        removeViewPageData: () => dispatch(dashBoardLeadAction.removeViewPageData()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
