import React, { Component } from 'react'
import "../dashboard/dashboard.css";
import { connect } from 'react-redux';
import history from '../../history';
import { Modal } from 'react-bootstrap';
import * as loginAction from '../../actions/index';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        let token = localStorage.getItem("userToken")
        if (!token) {
            history.push("/")
        }
        if (this.props.userType === "admin") {
            this.props.modalAdminFun()
        }
    }
    onEyeClick = (row) => {
        history.push(`/dashboard/${row.id}/items`)
    }
    modalForView = (cell, row, rowIndex) => {
        return (
            <i onClick={this.onEyeClick.bind(this, row)} className="fa fa-eye eyeClass"></i>
        )
    }
    handleClose = () => {
        this.props.handleCloseAdminModal()
    };
    render() {
        return (
            <div className="dashboard container">
                <Modal size="md"
                    show={this.props.modalAdmin}

                    onHide={this.handleClose.bind(this)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>You Are Neither Professional Nor EstateLead</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center" }}>Please Login with an Estate Lead Or Professional </p>
                        <button className="btn btn-primary mrginToModl" onClick={this.handleClose}> Ok</button>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        userType: state.auth.loggedData.user_type,
        modalAdmin: state.auth.modalAdmin
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleCloseAdminModal: () => dispatch(loginAction.handleCloseAdminModal()),
        modalAdminFun: () => dispatch(loginAction.modalAdminFun())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
