import React, { Component } from 'react'
import { Navbar, Nav, Form } from 'react-bootstrap';
import "./navbar.css";
import { connect } from 'react-redux';
import history from "../history"
import * as loginAction from '../actions/index';
import logo from '../assets/images/logo-2.png';

class NavBarr extends Component {
    homeClick = (e) => {
        e.preventDefault();
        history.push("/")
    }
    brandClick = () => {
    }
    signInPage = () => {
        history.push("/")
    }
    componentDidMount() {
    }
    render() {
        return (
            <Navbar className="navbarcolor" sticky="top"  >
                <Navbar.Brand onClick={this.brandClick.bind(this)} >
                    <img className="logoImg" height="25px" src={logo} alt="Not Found" />
                </Navbar.Brand>
                <Nav className="mr-auto">
                </Nav>
                <Form inline>
                    {this.props.isSignedIn && localStorage.getItem("userToken") ? <Nav.Link className="navclass" onClick={() => {
                        localStorage.removeItem("userToken");
                        localStorage.removeItem("email");
                        localStorage.removeItem("nextPageValues");
                        history.push("/")
                        this.props.logout()
                    }}>Logout</Nav.Link> :
                        <Nav.Link className="navclass" onClick={this.homeClick} 
                        >Home</Nav.Link>}
                </Form>
            </Navbar>

        )
    }
}
const mapStateToProps = (state) => {

    return {

        isSignedIn: state.auth.isSignedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(loginAction.signOut()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBarr)