import React, { Component } from 'react'
import { connect } from 'react-redux';
import bessemer from '../../assets/images/bessemer3.png';
import loginLogo from '../../assets/images/EstateClarityLogo_Blue_HorizAsset 2@0.5x.png';
import Appstore from '../../assets/images/download-on-the-app-store-apple.svg';
import "./AppDownload.css"

class Dashboard extends Component {
    csvLink = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
      
        }
    }
    goToAppstore=() => {
        // window.location.href = 'https://apps.apple.com/us/app/estate-clarity/id1325185991'
        window.open('https://apps.apple.com/us/app/estate-clarity/id1325185991', '_blank');

    }
    componentDidMount() {
        let token = localStorage.getItem("userToken")
        if (token) {
            this.props.history.push("/dashboard")
        }
    }
    render() {
     
        return (
          
            <div className="container fit-content">
            <div className="outerLogoImg"><img className="loginlogosubmitAppDownload" src={loginLogo} alt="loginImg" /></div>
            <br />
            <p className="paraTagAppDownload">For</p>
            {this.props.logoUrl ?
                <div style={{ textAlign: "center" }}><img className="loginlogoUser" src={this.props.logoUrl} alt="loginImg" /> </div> :
                <div style={{ textAlign: "center" }} className=""> <img className="loginlogoUser" src={bessemer} alt="loginDefaultImg" /> </div>
            }

            <div className="text-center allpTags">
                <p className="pTag1">Welcome to Estate Clarity!</p>
                <p className="pTag2"> <b>To finish setting up your account, you need to download and login to the app.</b></p>
                <p className="pTag3">You can always come back here to view what you’ve added through the app.</p>
            </div>
            <div className="text-center">
            <img className="appStore" src={Appstore} onClick={this.goToAppstore} alt="Download On Appstore" />
            </div>
        </div>
       )
    }
}
const mapStateToProps = (state) => {

    return {
        // loggedData: state.auth.loggedData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        // completeDetailOfUser: (token, email) => dispatch(dashboardAction.completeDetailOfUser(token, email)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard) 
