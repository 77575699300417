const initialState = {
    dataAfterRegister: {},
    dataAfterRegisterUser: {},
    estateCompleteData: {},
    estateDataDb: [],
    noOfEstates: null,
    statApiData: [],
    estateLeads: [],
    viewPageData: [],
    viewPageStatData: [],
    estateItemsData: [],
    recipientData: []
}

export default (state = initialState, action) => {


    switch (action.type) {
        case "ESTATE_DATA_FROM_DB":
            return { ...state, estateCompleteData: action.payload, noOfEstates: action.payload.estate_count, estateDataDb: action.payload.data }

        case "FILT_DEST_STAT":
            return { ...state, statApiData: action.payload, }

        case "ESTATE_LEADS":
            return { ...state, estateLeads: action.payload.data.estate_leads }
        case "VIEW_PAGE_DATA":
            return { ...state, viewPageData: action.payload.data }
        case "VIEW_PAGE_DATA_STATS":
            return { ...state, viewPageStatData: action.payload.data }
        case "SIGN_OUT":
            return { ...state, estateCompleteData: [], noOfEstates: null, estateDataDb: [], statApiData: [], estateLeads: [], viewPageData: [], viewPageStatData: [] }
        case "REMOVE_VIEW_PAGE_DATA":
            return { ...state, viewPageData: [] }
        case "ESTATE_ITEMS_DATA_FROM_DB":
            return { ...state, estateItemsData: action.payload.data }
        case "RECIPIENT_DATA":
            return { ...state, recipientData: action.payload.recipient }

        default:
            return state

    }
}