export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const PROFESSIONAL_ACCOUNT_DETAILS = 'PROFESSIONAL_ACCOUNT_DETAILS';
export const ACCOUNT_MODAL_STATE = 'ACCOUNT_MODAL_STATE';
export const REGISTER_USER = 'REGISTER_USER';
export const PARTICULAR_ACCOUNT_DATA = 'PARTICULAR_ACCOUNT_DATA';
export const REMOVE_PARTICULAR_ACCOUNT_DATA = 'REMOVE_PARTICULAR_ACCOUNT_DATA';
export const LOGO_IMAGE_URL = 'LOGO_IMAGE_URL';
export const SIGN_IN_EMAIL = 'SIGN_IN_EMAIL';
export const ERROR_MODAL = 'ERROR_MODAL';
export const HANDLE_CLOSE = 'HANDLE_CLOSE';
export const HANDLE_CLOSE_ESTATE = 'HANDLE_CLOSE_ESTATE';
export const HANDLE_OPEN_ESTATE = 'HANDLE_OPEN_ESTATE';
export const HANDLE_OPEN_ADMIN_MODAL = 'HANDLE_OPEN_ADMIN_MODAL';
export const HANDLE_CLOSE_ADMIN_MODAL = 'HANDLE_CLOSE_ADMIN_MODAL';
export const USER_LOGO = 'USER_LOGO';
export const ESTATE_DATA_FROM_DB = 'ESTATE_DATA_FROM_DB';
export const FILT_DEST_STAT = 'FILT_DEST_STAT';
export const ESTATE_LEADS = 'ESTATE_LEADS';
export const VIEW_PAGE_DATA = 'VIEW_PAGE_DATA';
export const VIEW_PAGE_DATA_STATS = 'VIEW_PAGE_DATA_STATS';
export const REMOVE_VIEW_PAGE_DATA = 'REMOVE_VIEW_PAGE_DATA';
export const ESTATE_ITEMS_DATA_FROM_DB = 'ESTATE_ITEMS_DATA_FROM_DB';
export const RECIPIENT_DATA = 'RECIPIENT_DATA';












