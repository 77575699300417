import React, { Component } from 'react'
import "./viewPage.css";
import { connect } from 'react-redux';
import * as viewPageAction from '../../actions/index';
import history from '../../history';
import { CSVLink } from "react-csv";
import { getCollaborators } from "../../actions/index";
import { css } from '@emotion/core';
import bessemer from '../../assets/images/bessemer3.png';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button } from 'react-bootstrap';
import { BarLoader, MoonLoader, ScaleLoader } from 'react-spinners';
import ImageZoom from 'react-medium-image-zoom';
import { Modal } from 'react-bootstrap';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function imagesLoaded(parentNode) {
    const imgElements = [...parentNode.querySelectorAll("img")];
    for (let i = 0; i < imgElements.length; i += 1) {
        const img = imgElements[i];
        if (!img.complete) {
            return false;
        }
    }
    return true;
}
class ViewPage extends Component {
    csvLink = React.createRef()
    csvLinkCollab = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            ParticularEstateData: [],
            paramsIdState: null,
            csvData: [],
            csvDataCollab: [],
            name: "initial",
            loading: true,
            loadingActivities: true,
            pageNumber: 1,
            sizePerPageValue: 20,
            showModal: false,
            particularUrl: "",
            loadingImages: false,
            particularImageId:null

        }
    }
    componentDidMount() {
        let token = localStorage.getItem("userToken")
        let paramsId = this.props.match.params.id
        this.setState({
            paramsIdState: paramsId
        })
        if (!token) {
            history.push("/")
        }
        if (this.props.userType !== "professional" && this.props.userType !== "estate_lead" && this.props.userType !== "collaborator") {
            history.push("/dashboard")
        }
        this.props.getActivities(paramsId, token, (res) => {
            if (res) {
                this.setState({
                    loadingActivities: false
                })
            }
        });
        this.props.getRecipient(paramsId, token, (res) => {
            if (res) {
            }
        });

        this.props.getEstatesFromDb(token);
    }
    downloadImages = (row, index) => {
        this.setState({
            loadingImages: true,
            particularImageId:row.id
        })
        row.images.forEach((img, index) => {
            let IMAGEName = row.name;

            this.forceDownload(img, IMAGEName, (res) => {
                if (res) {
                    this.setState({
                        loadingImages: false
                    })
                }
            });
        });

    }
    forceDownload = (url, fileName, cb) => {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = xhr.handleStateChange;
        xhr.open("GET", url + ((/\?/).test(url) ? "&" : "?"), true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('Content-disposition', 'attachment');
        xhr.responseType = "blob";
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
            cb(imageUrl)
        }
        xhr.send();
    }
    infoClick = (cell, row, rowIndex) => {
        return (
            <div>
                <p className="infoestate"> <b>{cell}</b></p>
                <p className="infoestate"> <i> {row.category} </i></p>
                <div className="imgNdCsv">
                    { typeof row.images != "undefined" &&
                    row.images != null &&
                    row.images.length != null&& 
                    row.images.length > 0?<button
                        onClick={this.downloadImages.bind(this, row, rowIndex)}
                        className="csvbuttoninsidetable">
                        Download Images <i className="fa fa-download completeicon" ></i>
                    </button>:null}
                    {row.id===this.state.particularImageId&&
                    typeof row.images != "undefined" &&
                    row.images != null &&
                    row.images.length != null&& 
                    row.images.length > 0?
                    <div className="text-center">
                        <ScaleLoader
                            css={override}
                            sizeUnit={"px"}
                            size={25}
                            color={'#62a3f9'}
                            loading={this.state.loadingImages}
                        />
                    </div>
                    :null
                    }
                </div>
            </div>
        )
    }
    imageModal = (imageUrl, index) => {
        this.setState({ showModal: true, particularUrl: imageUrl })
    }
    renderImage(imageUrl, index) {
        return (
            <div key={index} className="particularImages">
                <ImageZoom
                    image={{
                        src: imageUrl,
                        alt: 'EstateImages',
                        className: 'paddding',
                        width: '100px',
                        onLoad: this.handleImageChange,
                        onError: this.handleImageChange

                    }}
                    zoomImage={{
                        src: imageUrl,
                        alt: 'EstateImages',
                        onClick: () => {window.open(imageUrl, "_blank")}
                    }}
                    defaultStyles={{overlay: {backgroundColor:"#00000073"},}}

                />
            </div>
        );
    }
    handleImageChange = () => {
        this.setState({
            loading: !imagesLoaded(this.galleryElement)
        });
    };
    renderSpinner() {
        if (!this.state.loading) {
            return null;
        }
        return <div className='sweet-loading'>
            <BarLoader
                css={override}
                sizeUnit={"px"}
                size={30}
                color={'#62a3f9'}
                loading={this.state.loading}
            />
        </div>;
    }
    particularImages = (cell, row, rowIndex) => {

        return (

            row.images.length > 0 ?
                <div
                    className="gallery"
                    ref={element => {
                        this.galleryElement = element;
                    }}
                >

                    <div className="images">
                        {row.images.map((imageUrl, index) => this.renderImage(imageUrl, index))}
                    </div>
                    {this.renderSpinner()}
                </div>

                :
                <div>
                    No Images
            </div>

        )
    }
    totalEnteredValueFormatter = (cell, row, rowIndex) => {
        let value = parseFloat(Math.round(row.value) / 100).toFixed(2)
        return (
            `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        )
    }
    destinationFormatter = (cell, row, rowIndex) => {
        if (row.destination === "assign" && row.assign_to!==null) {
            return (
                `Assigned to ${row.assign_to}`
            )
        }
        else if (row.destination === "assign" && row.assign_to===null) {

            return (
                `Assigned to ${"No Name"}`
            )
        }
        else if (!row.destination) {
            return ("No Destination")
        }
        else {
            return (
                `${row.destination}`
            )
        }
    }
    emptyData = () => {
        return this.state.loadingActivities ?
            <div style={{ textAlign: "center" }}> Loading</div> :
            <div style={{ textAlign: "center" }}> No Items</div>
    }
    downloadCsv = (values) => {
        let filteredData = this.props.viewPageData.filter((filterData, index) => {
            return filterData.type === "item"
        })
        // eslint-disable-next-line
        let csvData = [['Timestamp', 'Title', 'Price','Category', 'Description','Destination','IsAppraised'], ...filteredData.map(response => {
            if (response.type === 'item') {
                return Object.values({
                    timestamp: response.created_at,
                    title: response.name,
                    price: `$${parseFloat(Math.round(response.value) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                    category:response.category,
                    description:response.description,
                    destination: response.destination,
                    isAppraised: response.is_appraised===true?"Yes":(response.is_appraised===false?"No":"")
                })
            }
        })]

        this.setState({
            csvData,
            name: values.estateName ? values.estateName : values[0].name
        }
            , () => {
                this.csvLink.current.link.click()
            })
    }

    downloadCsvCollabs = (id, values) => {

        getCollaborators(id, (res) => {
            // eslint-disable-next-line
            let csvDataCollab = [['FirstName', 'LastName', 'Email', 'Role'], ...res['data'].data.map(response => {
                if (response) {
                    return Object.values({
                        FirstName: response.first_name,
                        LastName: response.last_name,
                        Email: response.email,
                        Role: response.user_role
                    })
                }
            })]

            this.setState({ csvDataCollab, name: values.estateName ? values.estateName : values[0].name }, () => {
                this.csvLinkCollab.current.link.click()
            })
        });
    }
    customTotal = (dataForTable,from, to, size) => {
        return (
            this.state.loadingActivities === false && dataForTable && dataForTable.length ?
                <div className="react-bootstrap-table-pagination-total">
                    Displaying  {from}-{to} of <b>{size}</b> items | Page {this.state.pageNumber} of {this.totalPages(this.state.sizePerPageValue, size)}
                </div>
                :
                null
        );
    }
    pageChange = (page, sizePerPage) => {
        this.setState({
            pageNumber: page,
            sizePerPageValue: sizePerPage
        })

    }
    totalPages(sizePerPage, size) {

        let totalPages = size / sizePerPage;

        if (size % sizePerPage === 0) {
            return totalPages
        }
        else {
            return Math.trunc(totalPages) + 1
        }
    }
    handleClose=() => {
        this.setState({
            showModal: false,
            particularUrl: ""
        })
    }
    render() {
        let dataForTable = this.props.viewPageData.filter((data) => {
            return (data.type === "item")
        })
        let totalEnteredValue = dataForTable.reduce(function (a, b) {
            return a + b.value
        }, 0);
        let paramsId = this.props.match.params.id
        var estateData = this.props.estateDataDb.filter((estateData) => {
            // eslint-disable-next-line
            return estateData.id == this.state.paramsIdState
        })
        let previousPageValues = JSON.parse(localStorage.getItem("nextPageValues"));
       
        const options = {
            sizePerPage: 20,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal.bind(this,dataForTable),
            onPageChange: this.pageChange.bind(this),
            onSizePerPageChange: this.onSizePerPageChange,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: dataForTable&&dataForTable.length
            }] 
        };
        
        const columns = [{
            dataField: 'name',
            text: 'Name and Category',
            formatter: this.infoClick.bind(this),
            formatExtraData: this.state.loadingImages
        },
        {
            dataField: 'id',
            text: 'Images',
            headerStyle: (colum, colIndex) => {
                return { width: '50%', textAlign: 'left' };
            },
            formatter: this.particularImages.bind(this),
            formatExtraData: this.state.loading


        },
        {
            dataField: 'no_of_items',
            text: 'Value',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '10%', textAlign: 'left' };
            },
            formatter: this.totalEnteredValueFormatter.bind(this),
        },
        {
            dataField: 'any',
            text: 'Destination',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%', textAlign: 'left' };
            },
            formatter: this.destinationFormatter.bind(this),
            formatExtraData: this.props.recipientData


        },
        ];

        return (
            <div className="dashboard container">
                <div className="row">
                    <div className="col-sm-12 backMargin">
                        <Button onClick={() => {
                            history.goBack()
                        }}
                            className="backbtnstyle">
                            Back
                            <i
                                className="fa fa-arrow-circle-left fa-1.5x back-icon"
                            />
                        </Button>
                        {/* <i
                            className="fa fa-arrow-circle-left fa-1.5x back-icon"
                            onClick={() => {
                                history.goBack()
                            }}> Back
                        </i> */}
                    </div>
                    <div className="col-sm-3 outerLogoViewPage">
                        {this.props.logoUrl ?
                            // <img className="loginlogoViewPage" src={this.props.logoUrl} alt="loginImg" height="75px" /> 
                            <ImageZoom
                                image={{
                                    src: this.props.logoUrl,
                                    alt: 'loginImg',
                                    className: 'loginlogoViewPage',
                                }}
                                zoomImage={{
                                    src: this.props.logoUrl,
                                    alt: 'loginImg'
                                }}
                            />
                            :
                            <img className="loginlogoViewPage" src={bessemer} alt="loginDefaultImg" height="75px" />
                        }
                    </div>
                    <div className="col-sm-9 inform">
                        <p className="welcometag"><b> Welcome, {this.props.loggedData && this.props.loggedData.username && this.props.loggedData.username.toUpperCase()}</b></p>
                        {estateData[0] ? <p className="estatename">  <b> Estate Name: {estateData[0] && estateData[0].name}</b></p> : null}
                        {previousPageValues ? <p className="estatename">  <b> Estate Name: {previousPageValues.estateName}</b></p> : null}
                        {/* {previousPageValues ? <p className="sameTags">  <b> Number of Items: </b> {previousPageValues.items}</p> : <p className="sameTags">  <b> Number of Items: </b> {dataForTable.length}</p>} */}
                        <p className="sameTags">  <b> Number of Items: </b> {dataForTable.length}</p>
                        {/* {previousPageValues ? <p className="sameTags">  <b> Total value: {`$${previousPageValues.itemValue}`}</b></p> : null} */}
                        <p className="sameTags">  <b> Total value: {`$${parseFloat(Math.round(totalEnteredValue) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</b></p>

                    </div>
                    <div className="col-sm-6 addnewitemtag">
                        <p className="sameTags" ><i><b> To add new Items and edit existing item details, please use the Estate Clarity App.</b> </i></p>
                    </div>

                    <div className="col-sm-6 csvbuttons">
                        <button
                            className="csvbuttonview"
                            onClick={this.downloadCsvCollabs.bind(this, paramsId, previousPageValues ? previousPageValues : estateData)}
                        >
                            Download CSV of Collaborators <i className="fa fa-download completeicon" ></i>
                        </button>
                        <CSVLink
                            filename={this.state.name + 'collaborator.csv'}
                            data={this.state.csvDataCollab}
                            className="hidden"
                            ref={this.csvLinkCollab}
                            target="_blank"
                        />
                        <button
                            onClick={this.downloadCsv.bind(this, previousPageValues ? previousPageValues : estateData)}

                            className="csvbuttonview">
                            Download CSV for all Items <i className="fa fa-download completeicon" ></i>
                        </button>
                        <CSVLink
                            filename={this.state.name + '.csv'}
                            data={this.state.csvData}
                            className="hidden"
                            ref={this.csvLink}
                            target="_blank"
                        />
                    </div>
                    <div className="container">
                        <BootstrapTable
                            keyField='id'
                            noDataIndication={this.emptyData.bind(this)}
                            data={dataForTable}
                            columns={columns}
                            striped
                            hover
                            condensed
                            pagination={paginationFactory(options)}
                        />
                    </div>
                    {this.state.loadingActivities ? <MoonLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'blue'}
                        loading={this.state.loading}
                    /> : null}
                </div>
                <Modal size="md" show={this.state.showModal} onHide={this.handleClose} className="imgModal">
                    <Modal.Body>
                        <div onClick={()=> window.open(this.state.particularUrl, "_blank")} style={{ textAlign: "center", cursor:"pointer" }}>
                            <img
                                src={this.state.particularUrl}
                                alt={"item"}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {

        loggedData: state.auth.loggedData,
        viewPageData: state.userData.viewPageData,
        viewPageStatData: state.userData.viewPageStatData,
        estateDataDb: state.userData.estateDataDb,
        logoUrl: state.auth.logoUrl,
        userType: state.auth.loggedData.user_type,
        recipientData: state.userData.recipientData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getActivities: (id, token, cb) => dispatch(viewPageAction.getActivities(id, token, cb)),
        getStatsForDestAndDesc: (id) => dispatch(viewPageAction.getDiscriptionAndDestinationFromDbProfessional(id)),
        getEstatesFromDb: (token) => dispatch(viewPageAction.getEstatesFromDb(token)),
        getRecipient: (id, token, cb) => dispatch(viewPageAction.getRecipient(id, token, cb)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewPage)
