import React, { Component } from 'react'
import "./footer.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
class Footer extends Component {
    render() {
        return (
            <>
                <footer id="sticky-footer" className="py-4 ">
                    <div className="container text-center">
                        <small className="footerCopyright"> &copy; Copyright 2019 Estate Clarity,Inc</small> <br />
                        <Link onClick={(e)=> {
                            e.preventDefault()
                        }} to="" className="fontSize">Terms Of Service</Link> |<Link onClick={(e)=> {
                            e.preventDefault()
                        }} to="" className="fontSize" > Privacy Policy</Link>
                    </div>
                </footer>
            </>
        )
    }
}
export default Footer;