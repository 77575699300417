import React, { Component } from 'react'
import "./loginPage.css";
import { connect } from 'react-redux';
import * as loginAction from '../../actions/index';
import loginLogo2 from '../../assets/images/EstateClarityLogo_Blue_HorizAsset 2@0.75x.png';
import { Modal } from 'react-bootstrap';



class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
        }
    }

    componentDidMount() {
        this.props.onileOrNot()
        let email = localStorage.getItem("email")
        let token = localStorage.getItem("userToken")

        if (!email || !this.props.isSignedIn || !token) {
            this.props.history.push("/")
        }
        if (token && this.props.isSignedIn) {
            this.props.history.push("/dashboard")
        }
    }
    handleClose = () => {
        this.props.handleClose()
    };
    nextSubmit = (event) => {
        event.preventDefault()
        let data = {
            email: this.state.email,
        }
        if (!navigator.onLine) {
            this.props.onileOrNot()

        }
        if (navigator.onLine) {
            this.props.getLogo(data.email)
        }
        if (navigator.onLine) {
            this.props.loginEmail(data, (res) => {
                if (res) {
                }
            })
        }

    }
    handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({ [key]: value });
    }
    render() {

        return (
            <div className="container fit-content">

                <div className="outerLogoImage">
                    <img className="bckroundimg" src={loginLogo2} alt="loginImg" height="75px" />

                </div>
                <div className="outerForm">
                    <form className="form-signin" onSubmit={this.nextSubmit.bind(this)}>
                        <div className="outerFormInput">
                            <div className="outerLabel"><label className=" emailclass ">Email Address </label></div>
                            <input name="email" type="email" id="inputEmail" className="form-control" onChange={this.handleChange} placeholder="Enter Your Email address" required autoFocus />
                            <button className="btn btn-lg  btn-block btn-signin" type="submit">Next</button>
                            <h2 className="invitationline">
                                Estate Clarity requires an invitation from a professional to get started. <br /><br />
                                Email hello@estateclarity.com for more information
                            </h2>
                        </div>
                    </form>
                    {/* Modal For Professional  */}
                </div>
                <Modal size="sm"
                    show={this.props.modalState}

                    onHide={this.handleClose.bind(this)} aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modalNoEmail"
                    dialogClassName="modal-90w">

                    <Modal.Body>

                        <p className="emailNotFound">The Email you entered is not associated with any accounts.</p>

                        <p className="emailNotFound">Double check the email you're entering matches the email you were invited with and try again</p>
                        <hr />
                        <button className="btn btn-primary mrginToModl emailNotFound" onClick={this.handleClose}> Ok</button>
                    </Modal.Body>
                </Modal>
                <Modal size="sm"
                    onHide={this.handleClose.bind(this)} aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Body>
                        You have not finished creating your account yet. Right now you need to do that from your smart phone.<br />
                        <p>Please download Estate Clarity using this link from your mobile phone:</p>
                        <p><a href="http://estateclarity.com">download.estateclarity.com</a></p>
                        <br />
                        <p>Once you finish getting started in an app, you can return here to login</p>
                        <button className="btn btn-primary mrginToModl" onClick={this.handleClose}> Ok</button>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {

        loggedData: state.auth.loggedData,
        modalState: state.auth.modalState,
        isSignedIn: state.auth.isSignedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loginEmail: (data, cb) => dispatch(loginAction.loginEmail(data, cb)),
        handleClose: () => dispatch(loginAction.handleClose()),
        getLogo: (email) => dispatch(loginAction.getLogo(email))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)